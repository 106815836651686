export default {
    RESOURCES_URL: 'https://rcb.rosie.health/api/resources',
    
    FLOW_URL_TEST: 'https://rcb.rosie.health/api/topic/5d4d75f5b6295c0794ab5d45/conversation/5e9b7b3578409b132d2245cd',
    
    FLOW_URL_SCHEDULE: 'https://rcb.rosie.health/api/topic/5d4d75f5b6295c0794ab5d45/conversation/5e9b7b3578409b132d2245cd',
    FLOW_URL_VOLUNTEER: 'https://rcb.rosie.health/api/topic/5d4d75f5b6295c0794ab5d45/conversation/5e73f354d36a260960bbf15d',
    
    
    
    FLOW_URL: 'https://rcb.rosie.health/api/topic/5ea05d89d7e1244bfddff12a/conversation/5ea601711ecf924e8a949a63',


    
    FLOW_URL_SCHEDULE_SR: 'https://rcb.rosie.health/api/topic/5d4d75f5b6295c0794ab5d45/conversation/5e9f19a278409b132d224601',
    FLOW_URL_VOLUNTEER_SR: 'https://rcb.rosie.health/api/topic/5d4d75f5b6295c0794ab5d45/conversation/5e73f354d36a260960bbf15d',
    FLOW_URL_SR: 'https://rcb.rosie.health/api/topic/5d4d75f5b6295c0794ab5d45/conversation/5e6f5c4ad36a260960bbf129',
    
    FLOW_URL_SCHEDULE_EN: 'https://rcb.rosie.health/api/topic/5d4d75f5b6295c0794ab5d45/conversation/5e9f199a78409b132d224600',
    FLOW_URL_VOLUNTEER_EN: 'https://rcb.rosie.health/api/topic/5d4d75f5b6295c0794ab5d45/conversation/5e73f354d36a260960bbf15d',
    FLOW_URL_EN: 'https://rcb.rosie.health/api/topic/5d4d75f5b6295c0794ab5d45/conversation/5e6f5c53d36a260960bbf12a',
    
    SUBMIT_URL: 'https://rosie.kosova.health/api/submitEvaluation',
}